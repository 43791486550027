<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/room" class="main_left" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
  <vxe-modal ref="xModal" title="入住办理" width="1000" height="660" show-zoom @close="closeEvent">
    <template v-slot>
      <vxe-form ref="xForm" v-bind="formOptions" :data="formData" v-loading="loading" @submit="submitEvent" @reset="closeEvent">
        <template #livePeople="{data}">
          <LivePeople :tableData="data.roommateDTOList" :currModel="currModel" />
        </template>
      </vxe-form>
    </template>
  </vxe-modal>

  <vxe-modal ref="livePeople" title="同住人信息" width="1000" show-zoom @close="closeEvent">
    <template v-slot>
      <LivePeople :tableData="formData.roommateDTOList" :currModel="currModel" />
    </template>
  </vxe-modal>

  <vxe-modal ref="audit" title="审核" width="600" resize remember>
    <template #default>
      <vxe-form :data="formDataAudit" ref="formDataAudit" :rules="rulesAudit" title-width="100" v-loading="loading" @submit="aprovalGrantedEvent('yes')" @reset="aprovalGrantedEvent('no')">
        <vxe-form-item title="审核意见" field="remark" span="24">
          <template #default>
            <vxe-textarea v-model="formDataAudit.remark" maxlength="300"></vxe-textarea>
          </template>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <template #default>
            <vxe-button type="submit" status="primary">审核通过</vxe-button>
            <vxe-button type="reset">驳回申请</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </template>
  </vxe-modal>
</div>
</template>

<script>
import { mapActions } from "vuex";
import LivePeople from './LivePeople'
export default {
  components: {
    LivePeople
  },
  data() {
    return {
      formDataAudit: {
        remark: ''
      },
      rulesAudit: {
        remark: [{ required: true, message: "请输入审核意见" }],
      },
      loading: false,
      selectRow: null,
      currModel: "add",
      currNode: {},
      parentNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "checkIn",
              name: "入住办理",
              status: "primary",
              events: {
                click: () => {
                  if (this.currNode.type == '1004') {
                    if (this.currNode.status != 1) {
                      this.$message.error('当前房间已经入住,请选择未办理入住的房间');
                    } else {
                      this.enabledEvent("add");
                      this.formOptions.data.roommateDTOList = [];
                      this.formData = { ...this.formOptions.data };
                      this.$refs.xModal.open();
                    }
                  } else {
                    this.$message.error('请选择房间号')
                  }
                }
              }
            },
            {
              code: "audit",
              name: "审核",
              status: "danger",
              events: {
                click: () => {
                  if (!this.selectRow) {
                    this.$message.error('请选择要审核的内容')
                  } else if (this.selectRow.status != 10) {
                    this.$message.error('请选择待审核的数据')
                  } else {
                    this.formDataAudit.remark = "";
                    this.$refs.audit.open();
                  }
                }
              }
            },
            // { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          export: true,
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "radio",
            width: 60,
          },
          {
            title: '基本信息',
            children: [{
                field: "communityName",
                title: "小区名称",
                minWidth: 120,
                editRender: {
                  name: "$input",
                  props: {
                    maxlength: 24,
                    placeholder: "请输入小区名称",
                  },
                },
              },
              {
                field: "buildingName",
                title: "楼栋编号",
                minWidth: 100,
                editRender: {
                  name: "$input",
                  props: {
                    placeholder: "请输入楼栋编号",
                  },
                },
              },
              {
                field: "unitName",
                title: "单元",
                minWidth: 100,
                editRender: {
                  name: "$input",
                  props: {
                    placeholder: "请输入单元",
                  },
                },
              },
              {
                field: "roomName",
                title: "房间号",
                minWidth: 100,
                editRender: {
                  name: "$input",
                  props: {
                    placeholder: "请输入房间号",
                  },
                },
              },
              {
                field: "leaseType",
                title: "租赁类型",
                minWidth: 100,
                editRender: {
                  name: "$select",
                  optionTypeCode: "leaseType",
                  props: {
                    placeholder: "请选择租赁类型",
                  },
                },
              },
              {
                field: "roomArea",
                title: "面积",
                minWidth: 100,
                formatter: ({ cellValue, row, column }) => {
                  if (!cellValue) {
                    return "/"
                  } else {
                    return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
                  }
                },
                editRender: {
                  name: "$input",
                  props: {
                    placeholder: "请输入面积",
                  },
                },
              },
              {
                field: "roomRental",
                title: "租金",
                minWidth: 100,
                formatter: ({ cellValue, row, column }) => {
                  if (!cellValue) {
                    return "/"
                  } else {
                    return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
                  }
                },
                editRender: {
                  name: "$input",
                  props: {
                    placeholder: "请输入租金",
                  },
                },
              },

            ]
          },
          {
            title: '申请人信息',
            children: [{
                field: "applicantName",
                title: "申请人",
                minWidth: 100,
                editRender: {
                  name: "$input",
                  props: {
                    placeholder: "请输入申请人",
                  },
                },
              },
              {
                field: "applicantPhone",
                title: "申请人电话",
                minWidth: 120,
                editRender: {
                  name: "$input",
                  props: {
                    placeholder: "请输入申请人电话",
                  },
                },
              },
              {
                field: "expirationDate",
                title: "到期时间",
                minWidth: 100,
                editRender: {
                  name: "$input",
                  props: {
                    type: "date",
                    valueFormat: "yyyy-MM-dd HH:mm:ss",
                    placeholder: "请选择到期时间",
                  },
                },
              },
            ]
          },
          {
            field: "approvalComments",
            title: "审批意见",
            minWidth: 200,
            editRender: {
              name: "$input",
            },
          },
          {
            fixed: "right",
            field: "status",
            title: "状态",
            width: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "reviewStatus",
              events: {
                change: ({ row }) => {
                  this.$refs.xGrid.submitEvent(row)
                }
              }
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 200,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  status: "primary",
                  label: "修改",
                  name: 'edit',
                  params: {
                    property: "status",
                    value: ["10"]
                  },
                  events: {
                    click: (row) => {
                      this.enabledEvent("edit");
                      this.selectRow = row;
                      this.queryEvent(row);
                    }
                  }
                },
                {
                  status: "primary",
                  label: "查看",
                  name: 'view',
                  events: {
                    click: (row) => {
                      this.enabledEvent("detail");
                      this.selectRow = row;
                      this.queryEvent(row);
                    }
                  }
                },
                {
                  status: "danger",
                  label: "删除",
                  name: 'delete',
                  eventName: "removeEvent",
                },
              ],
            },
          },
        ],
        editConfig: {
          enabled: false,
        },
        radioConfig: {
          trigger: "row",
          highlight: true,
        },
        events: {
          'radio-change': (data) => {
            this.selectRow = data.row;
          }
        },
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 1000,
        height: 600,
        titleWidth: 120,
        items: [{
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "房屋资料",
                position: "center"
              }
            },
          },
          {
            field: "communityName",
            title: "小区名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                placeholder: "请选择小区名称",
              },
            },
          },
          {
            field: "buildingName",
            title: "楼栋编号",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                placeholder: "请选择楼栋编号",
              },
            },
          },
          {
            field: "unitName",
            title: "单元",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                placeholder: "请选择单元",
              },
            },
          },
          {
            field: "roomName",
            title: "房间号",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                placeholder: "请选择房间号",
              },
            },
          },
          {
            field: "leaseType",
            title: "租赁类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "leaseType",
              props: {
                maxlength: 24,
                placeholder: "请选择租赁类型",
              },
            },
          },
          {
            field: "roomArea",
            title: "面积",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                type: 'float',
                maxlength: 24,
                placeholder: "请输入面积",
              },
            },
          },
          {
            field: "feeConfigId",
            title: "租金项",
            span: 12,
            itemRender: {
              name: "$select",
              props: {
                placeholder: "请选择租金项",
              },
              events: {
                change: ({ data, property }) => {
                  let item = this.feeConfigLists.filter(item => item.id === data[property]);
                  data["roomRental"] = item.length ? item[0].squarePrice : ""
                }
              }
            },
          },
          {
            field: "roomRental",
            title: "租金",
            span: 12,
            itemRender: {
              name: "$select",
              props: {
                disabled: true,
                placeholder: "请选择租金",
              },
            },
          },
          {
            field: "signDate",
            title: "签约日期",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择签约日期"
              }
            }
          },
          {
            field: "expirationDate",
            title: "到期时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择到期时间"
              }
            }
          },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "申请人详细资料",
                position: "center"
              }
            },
          },
          {
            field: "applicantDTO.photo",
            title: "照片",
            span: 12,
            itemRender: {
              name: "$VxeUpload",
              events: {
                click: (params, data) => {
                  params.data["applicantDTO"]["photo"] = data;
                },
              }
            },
          },
          // {
          //   field: "signature",
          //   title: "签名",
          //   span: 12,
          //   itemRender: {
          //     name: "$input",
          //     props: {
          //       placeholder: "请输入签名",
          //     },
          //   },
          // },
          {
            field: "applicantDTO.userName",
            title: "申请人姓名",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入申请人姓名",
              },
            },
          },
          {
            field: "applicantDTO.sex",
            title: "性别",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "sex",
              props: {
                placeholder: "请选择性别",
              },
            },
          },
          {
            field: "applicantDTO.nation",
            title: "民族",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入民族",
              },
            },
          },
          {
            field: "applicantDTO.age",
            title: "年龄",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入年龄",
              },
            },
          },
          {
            field: "applicantDTO.idCard",
            title: "身份证号",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 18,
                placeholder: "请输入身份证号",
              },
            },
          },
          {
            field: "applicantDTO.vocation",
            title: "单位/职业",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入单位/职业",
              },
            },
          },
          {
            field: "applicantDTO.phone",
            title: "联系电话",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 11,
                placeholder: "请输入联系电话",
              },
            },
          },
          {
            field: "applicantDTO.checkInTime",
            title: "入住时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择入住时间",
              },
            },
          },
          {
            field: "applicantDTO.health",
            title: "身体状态",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入身体状态",
              },
            },
          },
          {
            field: "applicantDTO.minimum",
            title: "是否低保",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "isIt",
              props: {
                placeholder: "请选择是否低保",
              },
            },
          },
          {
            field: "applicantDTO.incomeSituation",
            title: "收入情况",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入收入情况",
              },
            },
          },
          {
            field: "applicantDTO.censusRegister",
            title: "所在社区",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入所在社区",
              },
            },
          },
          {
            field: "applicantDTO.censusRegisterPhone",
            title: "社区电话",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入社区电话",
              },
            },
          },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "同住人信息资料",
                position: "center"
              }
            },
          },
          { span: 24, slots: { default: 'livePeople' } },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "监护人信息资料",
                position: "center"
              }
            },
          },
          {
            field: "guardianDTO.photo",
            title: "照片",
            span: 12,
            itemRender: {
              name: "$VxeUpload",
              events: {
                click: (params, data) => {
                  params.data["guardianDTO"]["photo"] = data;
                }
              }
            },
          },
          {
            field: "guardianDTO.userName",
            title: "姓名",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入监护人姓名",
              },
            },
          },
          {
            field: "guardianDTO.sex",
            title: "性别",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "sex",
              props: {
                placeholder: "请选择性别",
              },
            },
          },
          {
            field: "guardianDTO.relation",
            title: "与申请人关系",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "applicantRelation",
              props: {
                placeholder: "请选择与申请人关系",
              },
            },
          },
          {
            field: "guardianDTO.idCard",
            title: "身份证号",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 18,
                placeholder: "请输入身份证号",
              },
            },
          },
          {
            field: "guardianDTO.phone",
            title: "联系电话",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 11,
                placeholder: "请输入联系电话",
              },
            },
          },
          {
            field: 'remark',
            title: '描述',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入描述内容'
              }
            }
          },
          {
            field: "referMessage",
            title: "参考依据",
            span: 12,
            itemRender: {
              name: "$VxeUpload",
              events: {
                click: (params, data) => {
                  params.data["referMessage"] = data;
                }
              }
            },
          },
          {
            field: "operation",
            align: "center",
            span: 24,
            visibleMethod: ({ data }) => {
              if (this.currModel == "detail") {
                return false
              } else {
                return true
              }
            },
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          communityId: [{ required: true, message: "请选择小区名称" }],
          buildingId: [{ required: true, message: "请选择楼栋编号" }],
          unitId: [{ required: true, message: "请选择单元" }, ],
          roomId: [{ required: true, message: "请选择房间号" }, ],
          roomArea: [{ required: true, message: "请输入面积" }],
          roomRental: [{ required: true, message: "请输入租金" }],
          leaseType: [{ required: true, message: "请选择租赁类型" }],
          status: [{ required: true, message: "请选择审核状态" }],
          feeConfigId: [{ required: true, message: "请选择租金项" }],
          signDate: [{ required: true, message: "请选择签约日期" }],
          expirationDate: [{ required: true, message: "请选择到期时间" }],
          // 申请人信息
          "applicantDTO.photo": [{ required: true, message: "请上传照片" }, ],
          "applicantDTO.userName": [{ required: true, message: "请输入申请人姓名" }],
          "applicantDTO.sex": [{ required: true, message: "请选择性别" }],
          "applicantDTO.nation": [{ required: true, message: "请输入民族" }],
          "applicantDTO.age": [{ required: true, message: "请输入年龄" }],
          "applicantDTO.idCard": [
            { required: true, message: "请输入身份证号" },
            {
              validator({ itemValue }) {
                const IDCard = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
                if (itemValue === '') {
                  return new Error('请输入身份证号')
                } else if (!IDCard.test(itemValue)) {
                  return new Error('身份证号格式不正确!')
                }
              },
              trigger: 'blur'
            }
          ],
          "applicantDTO.vocation": [{ required: true, message: "请输入单位/职业" }],
          "applicantDTO.phone": [{ required: true, message: "请输入联系电话" },
            {
              validator({ itemValue }) {
                const phoneReg = /^([1]\d{10}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?)$/
                if (itemValue === '') {
                  return new Error('请输入手机号')
                } else if (!phoneReg.test(itemValue)) {
                  return new Error('手机号格式不正确!')
                }
              },
              trigger: 'blur'
            }
          ],
          "applicantDTO.checkInTime": [{ required: true, message: "请选择入住时间" }],
          "applicantDTO.health": [{ required: true, message: "请输入身体状态" }],
          "applicantDTO.minimum": [{ required: true, message: "请选择是否低保" }],
          "applicantDTO.incomeSituation": [{ required: true, message: "请输入收入情况" }],
          "applicantDTO.censusRegister": [{ required: true, message: "请输入所在社区" }],
          "applicantDTO.censusRegisterPhone": [{ required: true, message: "请输入社区电话" }],

          // 监护人信息
          "guardianDTO.userName": [{ required: true, message: "请输入监护人姓名" }],
          "guardianDTO.sex": [{ required: true, message: "请选择性别" }],
          "guardianDTO.relation": [{ required: true, message: "请输入与申请人关系" }],
          "guardianDTO.idCard": [{ required: true, message: "请输入身份证号" },
            {
              validator({ itemValue }) {
                const IDCard = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
                if (itemValue === '') {
                  return new Error('请输入身份证号')
                } else if (!IDCard.test(itemValue)) {
                  return new Error('身份证号格式不正确!')
                }
              },
              trigger: 'blur'
            }
          ],
          "guardianDTO.phone": [{ required: true, message: "请输入联系电话" },
            {
              validator({ itemValue }) {
                const phoneReg = /^([1]\d{10}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?)$/
                if (itemValue === '') {
                  return new Error('请输入手机号')
                } else if (!phoneReg.test(itemValue)) {
                  return new Error('手机号格式不正确!')
                }
              },
              trigger: 'blur'
            }
          ],
          "guardianDTO.photo": [{ required: true, message: "请上传照片" }],
        },
        // 新增功能字段初始化
        data: {
          leaseType: "",
          feeConfigId: "",
          roomRental: "",
          status: "10",
          applicantDTO: {
            sex: "1",
            minimum: "0",
            checkInTime: ""
          },
          roommateDTOList: [],
          guardianDTO: {
            sex: "1",
            relation: ''
          },
          signDate: '',
          expirationDate: '',
        },
      },
      formData: {},
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "roomName",
            title: "房间号",
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请选择房间号",
              },
            },
          },
          {
            field: "leaseType",
            title: "租赁类型",
            itemRender: {
              name: "$select",
              optionTypeCode: "leaseType",
              props: {
                maxlength: 24,
                placeholder: "请选择租赁类型",
              },
            },
          },
          {
            field: "status",
            title: "审核状态",
            itemRender: {
              name: "$select",
              optionTypeCode: "reviewStatus",
              props: {
                maxlength: 24,
                placeholder: "请选择租赁类型",
              },
            },
          },
          // {
          //   field: "expirationDate",
          //   title: "到期时间",
          //   folding: true,
          //   itemRender: {
          //     name: "$input",
          //     props: {
          //       type: "date",
          //       valueFormat: "yyyy-MM-dd HH:mm:ss",
          //       placeholder: "请选择到期时间"
          //     }
          //   }
          // },
          {
            field: "applicantName",
            title: "申请人姓名",
            folding: true,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入申请人姓名",
              },
            },
          },
          {
            field: "applicantPhone",
            title: "联系电话",
            folding: true,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 11,
                placeholder: "请输入联系电话",
              },
            },
          },
          {
            align: "center",
            collapseNode: true,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          roomName: '',
          leaseType: '',
          status: '',
          applicantName: '',
          applicantPhone: '',
        },
      },
      feeConfigLists: []
    };
  },
  created() {
    this.getSysDictEvent();
  },
  methods: {
    ...mapActions([
      "queryEventService",
      "submitEventService",
      "getSysDictService",
      "postEventService",
      "findByEventService"
    ]),

    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.getFeeConfigLists(node);
      if (node.type == "1004") {
        this.getRoomInfo(node);
        let unit = this.$Tools.getNodeById(treeData, node.parentId, "id");
        let building = this.$Tools.getNodeById(treeData, unit.parentId, "id");
        let community = this.$Tools.getNodeById(treeData, building.parentId, "id");
        let { data } = this.formOptions;

        data["communityId"] = community.id;
        data["communityName"] = community.name;
        data["buildingId"] = building.id;
        data["buildingName"] = building.name;
        data["unitId"] = unit.id;
        data["unitName"] = unit.name;
        data["roomId"] = node.id;
        data["roomName"] = node.name;
      }

      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "communityId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "communityId");
      this.searchOptions.data["parentId"] = node.id;
      this.searchOptions.data["parentType"] = node.type;
      this.$refs.xGrid.getLists();
    },

    // 查询房屋信息
    getRoomInfo(row) {
      this.queryEventService({
        service: '/communitys/tBuildingRoom',
        params: row,
      }).then(({ code, data }) => {
        if (code == 200) {
          this.formOptions.data["roomArea"] = data["roomBuiltUpArea"] || "0.00"
        }
      });
    },

    // 获取数据字典
    getSysDictEvent() {
      this.getSysDictService({
          params: ["leaseType", "easeType", "sex", "isIt", "reviewStatus", 'applicantRelation']
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.formOptions.items = [...this.$Tools.buildDataByDicts(this.formOptions.items, data, { value: "code", label: "name" })];
          }
        });
    },

    // 获取费用项
    getFeeConfigLists(node) {
      this.findByEventService({
          service: "/fee/fPayFeeConfig",
          params: {
            parentId: node.communityId || node.id
          }
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.feeConfigLists = data;
            this.$Tools.buildDataToGrid(this.formOptions.items, data, "feeConfigId", { value: "id", label: "feeName" });
          }
        });
    },

    // 编辑事件
    queryEvent(row) {
      this.loading = true;
      this.queryEventService({
          service: "/communitys/checkIn",
          params: row,
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.formData = data;
            this.$refs.xModal.open();
          }
          this.resetEvent();
        })
        .catch((err) => {
          this.resetEvent();
        });
    },

    // 审核事件
    aprovalGrantedEvent(status) {
      this.$refs["formDataAudit"].validate((valid) => {
        if (!valid) {
          if (this.loading) return;
          this.loading = true;
          this.postEventService({
              service: "/communitys/checkIn/approve",
              params: {
                id: this.selectRow.id,
                remark: this.formDataAudit.remark,
                status
              },
            })
            .then(({ code, data }) => {
              if (code == 200) {
                this.$message.success(status == 'yes' ? '审核通过' : '审核驳回')
                this.$refs.audit.close()
                this.$refs.xGrid.getLists();
              }
              this.resetEvent();
            })
            .catch((err) => {
              this.resetEvent();
            });
        }
      });
    },

    // 保存提交事件
    submitEvent({ data }) {
      if (this.loading) return;
      this.loading = true;
      this.$refs["xForm"].validate((valid) => {
        console.log(valid)
        if (!valid) {
          this.submitEventService({
            service: "/communitys/checkIn",
            params: data,
          }).then(({ code }) => {
            if (code == 200) {
              this.$refs.xGrid.getLists();
              this.$message.success(data.id ? "更新成功" : "保存成功");
              this.closeEvent();
            }
            this.resetEvent();
          }).catch((err) => {
            this.resetEvent();
          });
        } else {
          this.$message.error("请完善数据");
        }
      });
    },

    resetEvent() {
      this.loading = false;
    },

    closeEvent() {
      this.$refs.xModal.close();
    },

    enabledEvent(type) {
      this.currModel = type;
      let propertys = [];
      let arrs = ["communityName", "buildingName", "unitName", "roomName", "roomArea", "roomRental"];
      let { items } = this.formOptions;
      items.forEach(item => {
        if (item.field && arrs.indexOf(item.field) == -1) {
          propertys.push(item.field)
        }
      });

      this.$Tools.enabledEvent(items, propertys, type == "detail" ? true : false)
    },
  },
};
</script>

<style lang="scss" scoped></style>
