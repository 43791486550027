<template>
<div class="table">
  <vxe-grid ref="xGrid" height="400" :loading="loading" v-bind="gridOptions" :data="tableData" @toolbar-button-click="toolbarCustomEvent" @edit-closed="submitEvent"></vxe-grid>

  <!-- 新增、编辑功能 -->
  <vxe-modal ref="xModal" v-bind="formOptions" :title="formData._XID ? $t('editAndSave') : $t('createAndSave')" show-zoom>
    <template v-slot>
      <vxe-form ref="xForm" v-bind="formOptions" :data="formData" v-loading="loading" @submit="submitEvent" @reset="closeEvent"></vxe-form>
    </template>
  </vxe-modal>
</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "VxeFormTable",
  props: ["tableData", "currModel"],
  data() {
    return {
      loading: false,
      gridOptions: {
        // 列表字段配置
        // 列表功能按钮组
        toolbarConfig: {
          enabled: this.currModel == "detail" ? false : true,
          buttons: [{
              code: "customEvent",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  this.formData = { ...this.formOptions.data }
                  this.$refs.xModal.open();
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
        },
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "photo",
            width: 80,
            title: "照片",
            editRender: {
              name: "$VxePreview",
              props: {
                placeholder: "请上传照片"
              }
            }
          },
          {
            field: "userName",
            minWidth: 100,
            title: "姓名",
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入姓名"
              }
            }
          },
          {
            field: "sex",
            minWidth: 60,
            title: "性别",
            editRender: {
              name: "$select",
              optionTypeCode: "sex",
              props: {
                placeholder: "请选择性别"
              }
            }
          },
          {
            field: "idCard",
            title: "身份证号",
            minWidth: 160,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "phone",
            title: "联系电话",
            minWidth: 80,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "relation",
            title: "与申请人关系",
            minWidth: 70,
            editRender: {
              name: "$select",
              optionTypeCode: "applicantRelation",
              props: {
                placeholder: "请输入与申请人关系"
              }
            }
          },
          {
            field: "vocation",
            title: "职业",
            minWidth: 80,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入职业"
              }
            }
          },
          {
            fixed: "right",
            title: "操作",
            width: 120,
            cellRender: {
              name: "$buttons",
              children: [{
                props: {
                  type: "text",
                  status: "primary",
                  content: "修改",
                },
                events: {
                  click: ({ row }) => {
                    this.formData = { ...row };
                    this.$refs.xModal.open();
                  }
                }
              }, ],
            },
          },
        ],
        //表单校验规则
        editRules: {
          userName: [
            { required: true, message: "请输入姓名" },
          ],
        },
        // editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 120,
        items: [{
            title: '左侧',
            span: 10,
            children: [{
              field: "photo",
              title: "照片",
              span: 24,
              align: "center",
              itemRender: {
                name: "$VxeUpload",
                events: {
                  click: (params, data) => {
                    params.data["photo"] = data;
                  }
                }
              },
            }, ]
          },
          {
            title: '右侧',
            span: 14,
            children: [{
                field: "userName",
                title: "姓名",
                span: 24,
                itemRender: {
                  name: "$input",
                  props: {
                    maxlength: 24,
                    placeholder: "请输入姓名",
                  },
                },
              },
              {
                field: "sex",
                title: "性别",
                span: 24,
                itemRender: {
                  name: "$select",
                  optionTypeCode: "sex",
                  props: {
                    maxlength: 24,
                    placeholder: "请选择性别",
                  },
                },
              },
              {
                field: "idCard",
                title: "身份证号",
                span: 24,
                itemRender: {
                  // name: "$VxeIDCardInput",
                  name: "$input",
                  props: {
                    maxlength: 24,
                    placeholder: "请输入身份证号",
                  },
                },
              },
              {
                field: "phone",
                title: "联系电话",
                span: 24,
                itemRender: {
                  // name: "$VxePhoneInput",
                  name: "$input",
                  props: {
                    maxlength: 24,
                    placeholder: "请输入联系电话",
                  },
                },
              },
              {
                field: "relation",
                title: "与申请人关系",
                span: 24,
                itemRender: {
                  name: "$select",
                  optionTypeCode: "applicantRelation",
                  props: {
                    maxlength: 24,
                    placeholder: "请输入与申请人关系",
                  },
                },
              },
              {
                field: "vocation",
                title: "职业",
                span: 24,
                itemRender: {
                  name: "$input",
                  props: {
                    maxlength: 24,
                    placeholder: "请输入职业",
                  },
                },
              },
            ]
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          photo: [{ required: true, message: "请上传照片" }],
          userName: [{ required: true, message: "请输入姓名" }],
          sex: [{ required: true, message: "请选择性别" }],
          idCard: [{ required: true, message: "请输入身份证号" }, ],
          phone: [{ required: true, message: "请输入联系电话" }, ],
          relation: [{ required: true, message: "请输入与申请人关系" }],
          vocation: [{ required: true, message: "请输入职业" }],
        },
        // 新增功能字段初始化
        data: {
          sex: "1"
        },
      },
      formData: {},
    };
  },
  created() {
    this.getSysDictEvent();
  },
  methods: {
    ...mapActions(['getSysDictService']),
    // 获取数据字典
    getSysDictEvent() {
      this.getSysDictService({
          params: ["sex", 'applicantRelation']
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.gridOptions.columns = [...this.$Tools.buildDataByDicts(this.gridOptions.columns, data, { value: "code", label: "name" })];
            this.formOptions.items = [...this.$Tools.buildDataByDicts(this.formOptions.items, data, { value: "code", label: "name" })];
          }
        });
    },
    // 列表按钮组功能通用事件
    toolbarCustomEvent({ code, button }) {
      const { xGrid } = this.$refs;
      if (button.events) {
        Object.keys(button.events).forEach((key, index) => {
          if (index == 0) button.events[key](xGrid);
        });
      }
    },

    // 保存提交事件
    submitEvent({ data }) {
      if (!data._XID) {
        this.tableData.push(data);
      } else {
        this.tableData.map((item, index) => {
          if (item._XID == data._XID) {
            this.tableData[index] = data;
          }
        });
        this.$refs.xGrid.reloadData(this.tableData)
      }
      this.closeEvent();
    },

    closeEvent() {
      this.$refs.xModal.close();
    }

  },
};
</script>

<style lang="scss" scoped>
.table {
  padding: 0 10px 10px;
  overflow: hidden;
}
</style>
